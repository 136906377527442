import React from 'react'

function About() {
  return (
    <div>
         <main className="wrapper">
        {/* About Section */}
        <section data-scroll-index={1} className="section about-section border-top-g" id='about'>
          <div className="container">
            <div className="row align-items-start">
              <div className="col-md-5 col-xl-4 mx-auto sticky-md-top">
                <div className="about-img-box">
                  <div className="about-img">
                    <img src="assets/img/1.jpg" title alt="" />
                  </div>
                  <div className="nav social-icons justify-content-center">
                    <a className="facebook" href="https://www.facebook.com/sahil.attar.351"><i className="fab fa-facebook-f" /></a>
                    <a className="twitter" href="https://twitter.com/SahilAt57500309?t=gCaHMUCYXkDrDuob3nZU7w&s=09"><i className="fab fa-twitter" /></a>
                    <a className="instagram" href="https://www.instagram.com/ll_sahilattar_ll/?hl=en"><i className="fab fa-instagram" /></a>
                    <a className="linkedin" href="https://www.linkedin.com/in/sahil-attar-95ba5523b/"><i className="fab fa-linkedin-in" /></a>
                    
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-lg-6 pt-5 pt-lg-0">
                <div className="about-text">
                  <div className="about-row">
                    <h3>Biography</h3>
                    <p><p>Hi, I'm Sahil Attar, a MERN Stack Developer with a strong commitment to delivering high-quality work. Currently, I am available for freelance opportunities. If you have a project you'd like to kickstart or if you think you could use my expertise, feel free to get in touch.</p>
</p>
                  </div>
                  <div className="about-row">
                    <h3>Education</h3>
                    <div className="row g-4">
                      <div className="col-6">
                        <h5>SSC</h5>
                        <p>S.k.Pant.walawalkar Highschool , Kolhapur.</p>
                        <small>2017</small>
                      </div>
                      <div className="col-6">
                        <h5>HSC</h5>
                        <p>Vivekanand College, Kolhapur</p>
                        <small>2019</small>
                      </div>
                      <div className="col-6">
                        <h5>B.Tech in Computer Science Engineering</h5>
                        <p>Sanjay Ghodhawat University</p>
                        <small>2023</small>
                      </div>
                      <div className="col-6">
                        <h5>MBA In BlockChain Management</h5>
                        <p>D.Y.Patil University</p>
                        <small>2026</small>
                      </div>
                    </div>
                  </div>
                  <div className="about-row">
                    <h3>Experience</h3>
                    <div className="row g-4">
                      {/* <div className="col-6">
                        <h5>Web Developer </h5>
                        <p>AAROHIINFO FI MANAGEMENT</p>
                        <small>April 23 — Present</small>
                      </div>
                      <div className="col-6">
                        <h5>React Developer Intern</h5>
                        <p>Softtech Kolhapur</p>
                        <small>Jan 2023 — April 23</small>
                      </div> */}
                     
                    </div>
                  </div>
                  <div className="about-row">
                    <div className="row g-4">
                      <div className="col-6">
                        <h3>Competences</h3>
                        <ul>
                          <li>Express.js and Node.js Development</li>
                          <li>MongoDB Data Modeling</li>
                          <li>React for User Interface Development</li>
                          <li>State Management with Redux</li>
                          <li>RESTful API Design</li>
                          <li>Real-time Applications with Socket.io</li>
                          <li>Web Scraping with Node.js</li>
                        </ul>

                      </div>
                      <div className="col-6">
                        <h3>Tools</h3>
                        <ul>
                          <li>MongoDB - Database</li>
                          <li>Express.js - Server Framework</li>
                          <li>React - Frontend Library</li>
                          <li>Node.js - Backend Framework</li>
                          <li>Azure CI/CD</li>
                          <li>Git - Version Control System</li>
                          <li>Jest Mocha -Testing</li>
                       </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End About Section */}
      </main>
    </div>
  )
}

export default About