import React from 'react'

function Services() {
  const styles={
    card:{
      Height:'300px'
    }
  }
  return (
    <div>
        <main className="wrapper">
        {/* Services Section */}
        <section data-scroll-index={2} className="section services-section border-top-g" id='services'>
          <div className="container">
            <div className="row justify-content-center section-heading">
              <div className="col-lg-6 text-center">
                <h6><span className="text-theme">Serverices</span></h6>
                <h3>Services provide for you</h3>
                <p>We are Providing Best Services in IT World.</p>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-sm-6 col-lg-4"> 
                <div className="feature-box-01 media" style={styles.card}>
                <div className="icon"><i className="fab fa-react" /></div>
                  <div className="feature-content col">
                    <h5>React</h5>
                    <p>I have over 2 years of software development experience in React.js, creating interactive user interfaces, components, and various applications. My projects include the development of dynamic web applications, state-of-the-art UI components, and the implementation of React best practices.</p>
                  </div>
                </div>

              </div>
              <div className="col-sm-6 col-lg-4" style={styles.card}>
                <div className="feature-box-01 media">
                  <div className="icon"><i className="bi bi-phone" /></div>
                  <div className="feature-content col">
                    <h5>React Native</h5>
                    <p> Over the past 2 years, I've gained extensive experience in React Native development. My expertise includes building cross-platform mobile applications for iOS and Android..</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div className="feature-box-01 media" style={styles.card}>
                  <div className="icon"><i className="fab fa-node-js" /></div>
                  <div className="feature-content col">
                    <h5>NODE.js</h5>
                    <p>I have extensive experience in Node.js and many of its modules, including Express, Request, Grunt, Mocha, Cheerio, Bcrypt, and Oauth2-server, and have created many of my own, including cda-schematron..</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div className="feature-box-01 media" style={styles.card}>
                  <div className="icon"><i className="bi bi-distribute-vertical" /></div>
                  <div className="feature-content col">
                    <h5>Development</h5>
                    <p>With a combined 2 years of experience from personal projects and work at Wolfox Services , I often develop full-stack applications, consisting of HTML/JavaScript/CSS, various web libraries, and a Node.js/MongoDB backend..</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4" style={styles.card}>
                <div className="feature-box-01 media">
                  <div className="icon"><i className="fa fa-laptop" /></div>
                  <div className="feature-content col">
                    <h5>jQuery, Durandal, Bootstrap</h5>
                    <p>I have significant experience using web libraries such as Durandal, jQuery, and Bootstrap to create web applications for many of my professional and personal projects.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
  <div className="feature-box-01 media" style={styles.card}>
    <div className="icon"><i className="bi bi-people" /></div>
    <div className="feature-content col">
      <h5>Project Consulting</h5>
      <p>I specialize in providing project consulting services, leveraging my expertise in guiding and advising on various projects. With a background in machine learning research, I bring a unique perspective to project consulting. I have experience in collaborating on diverse projects and offering valuable insights for successful project execution.</p>
    </div>
  </div>
</div>

            </div>
          </div>
        </section>
        {/* End Services Section */}
      </main>
    </div>
  )
}

export default Services
