import React from 'react'
import Header from './Components/Header'
import Home from './Components/Home'
import About from './Components/About'
import Services from './Components/Services'
import Education from './Components/Education'
import Contact from './Components/Contact'
import Effects from './Components/Effects'
import Footer from './Components/Footer'

function App() {
  return (
    <>
    <Header/>
    <Home/>
    <About/>
    <Services/>
    {/* <Education/> */}
    <Contact/>
    {/* <Effects/> */}
    <Footer/>
    </>
  )
}

export default App