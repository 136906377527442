import React from 'react'

function Index() {
  return (
    <div>
      <div>
        {/* page loading */}
        {/* <div id="loading">
          <div className="load-circle"><span className="one" /></div>
        </div> */}
        {/* End */}
        {/* Header */}
        <header className="main-header">
          <nav className="navbar header-nav header-white header-transparent navbar-expand-lg one-page-nav">
            <div className="container">
              {/* Brand */}
              <a className="navbar-brand" href="http://pxdraft.com/themeforest/weasley/">
                <img src="assets/img/logo-light.svg" title alt="" />
              </a>
              {/* / */}
              {/* Mobile Toggle */}
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-collapse-toggle" aria-controls="navbar-collapse-toggle" aria-expanded="false" aria-label="Toggle navigation">
                <span />
                <span />
                <span />
              </button>
              {/* / */}
              {/* Top Menu */}
              <div className="collapse navbar-collapse justify-content-end" id="navbar-collapse-toggle">
                <ul className="navbar-nav ml-auto">
                  <li><a className="nav-link" data-scroll-nav={0} href="#home"><span>Home</span></a></li>
                  <li><a className="nav-link" data-scroll-nav={1} href="#about"><span>About Us</span></a></li>
                  <li><a className="nav-link" data-scroll-nav={2} href="#services"><span>Skills</span></a></li>
                  {/* <li><a className="nav-link" data-scroll-nav={3} href="#work"><span>Portfolio</span></a></li> */}
                  <li><a className="nav-link" data-scroll-nav={4} href="#contact"><span>Contact</span></a></li>
                </ul>
              </div>
              {/* / */}
            </div>{/* Container */}
          </nav> {/* Navbar */}
        </header>
        {/* End Header */}
      </div>
    </div>
  )
}

export default Index