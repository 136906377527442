import React from 'react'

function Footer() {
  return (
    <div>
         {/* Footer */}
      <footer className="footer white py-2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 py-2">
              <div className="nav justify-content-center justify-content-md-start">
                <a href="https://www.facebook.com/sahil.attar.351"><i className="fab fa-facebook-f" /></a>
                <a href="https://twitter.com/SahilAt57500309?t=gCaHMUCYXkDrDuob3nZU7w&s=09"><i className="fab fa-twitter" /></a>
                <a href="https://www.instagram.com/ll_sahilattar_ll/?hl=en"><i className="fab fa-instagram" /></a>
                <a href="https://www.linkedin.com/in/sahil-attar-95ba5523b/"><i className="fab fa-linkedin-in" /></a>               
              </div>
            </div>
            <div className="col-md-6 py-2 text-center text-md-end">
              <p className="m-0">© 2023 copyright all right reserved</p>
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer */}
    </div>
  )
}

export default Footer;